import React, { useContext } from "react";
import { Box, Grid, Heading, Header, Menu } from "grommet";

import appContext from "../context/appContext";
import { StaticMap, InteractiveMap } from "./Map";
import QuickFacts from "./QuickFacts";
import QuickComparison from "./QuickComparison";
import ReportListStandard from "./ReportList/ReportListStandard";
import { GeoLevelAlerts } from "./DataAlerts";
import { useViewport } from "../../../components/ReactContext_Viewport";
// import SelectedLocationList from "./SelectedLocationList";
import Reports from "./Reports";

const AppMain = () => {
    const { screenSize, appWidth } = useViewport();
    const { isMultipleGeosViewVisible, associatedCounty, selectedLocations } = useContext(appContext);
    // const mapContainer = useRef();
    return appWidth < 1000 ? (
        <Box>
            <Box height={isMultipleGeosViewVisible ? "60vh" : "120px"}>
                {isMultipleGeosViewVisible ? <InteractiveMap /> : <StaticMap />}
            </Box>
            <Box background="light-3" height={{ min: "fit-content" }}>
                {selectedLocations.length === 0 ? null : selectedLocations.length > 1 ? (
                    <QuickComparison />
                ) : (
                    <QuickFacts
                        primaryGeo={selectedLocations[0]}
                        secondaryGeo={
                            selectedLocations[0].geo_level === "place" ||
                            (selectedLocations[0].geo_level === "tribal" && associatedCounty)
                                ? associatedCounty
                                : null
                        }
                    />
                )}
            </Box>

            <Box background="light-1" height={{ min: "fit-content" }}>
                <Header pad={{ horizontal: "small", vertical: "xsmall" }} background="#2E5573">
                    <Heading level="2" size="xlarge" margin="0">
                        Get socioeconomic profiles <GeoLevelAlerts />
                    </Heading>
                    <Menu
                        dropProps={{
                            align: { top: "bottom", left: "right" },
                            elevation: "xlarge",
                        }}
                        gap="xsmall"
                        label="Jump to"
                        items={[
                            { label: "Comprehensive", href: "#comprehensive" },
                            { label: "Natural Hazards", href: "#naturalhazards" },
                            { label: "Land Management", href: "#landmanagement" },
                            { label: "Industries", href: "#industries" },
                        ]}
                    />
                </Header>
                <Box pad={{ vertical: "xsmall", horizontal: "small" }} height={{ min: "fit-content" }}>
                    <ReportListStandard cat="Comprehensive" anchor="comprehensive" />
                    <ReportListStandard cat="Natural Hazards" anchor="naturalhazards" />
                    <ReportListStandard cat="Land Management" anchor="landmanagement" />
                    <ReportListStandard cat="Industries" anchor="industries" />
                </Box>
            </Box>
        </Box>
    ) : (
        // ) : isMultipleGeosViewVisible ? (
        //     <Box fill justify="start" direction={"row-reverse"}>
        //         <Box fill ref={mapContainer}>
        //             <Map />
        //         </Box>
        //         <SelectedLocationList />
        //     </Box>
        <Grid
            fill
            rows={["fit-content", "1fr"]}
            columns={["auto", screenSize === "desktop" ? "550px" : "450px"]}
            areas={[
                ["map", "map"],
                ["reportList", "quickFacts"],
            ]}
        >
            <Box gridArea="map" height={isMultipleGeosViewVisible ? "50vh" : "160px"}>
                {isMultipleGeosViewVisible ? <InteractiveMap /> : <StaticMap />}
            </Box>
            <Box gridArea="reportList" background="#e9e9e9" overflow={{ vertical: "auto" }} elevation="small">
                <Reports />
            </Box>
            <Box gridArea="quickFacts" background="light-2" height={{ min: "fit-content" }}>
                {selectedLocations.length === 0 ? null : selectedLocations.length > 1 ? (
                    <QuickComparison />
                ) : (
                    <QuickFacts
                        primaryGeo={selectedLocations[0]}
                        secondaryGeo={selectedLocations[0].geo_level === "place" || (selectedLocations[0].geo_level === "tribal" && associatedCounty) ? associatedCounty : null}
                    />
                )}
            </Box>
        </Grid>
    );
};

export default AppMain;
