import React from "react";
import { Box, Heading, Header, Text, DropButton, Anchor } from "grommet";
import { useViewport } from "../../../components/ReactContext_Viewport";
import ReportListStandard from "./ReportList/ReportListStandard";
import { GeoLevelAlerts } from "./DataAlerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Reports = () => {
    const { screenSize } = useViewport();
    return (
        <>
            <Header id="reportListHeader" pad={{ horizontal: "xsmall", vertical: "xxsmall" }} background="heBlue-4">
                <Box direction="row" align="center" gap="xsmall">
                    <Heading level="2" size="xlarge" margin="0">
                        Get Socioeconomic Profiles
                    </Heading>
                    {/* <GeoLevelAlerts /> */}
                </Box>
                {screenSize === "tablet" ? (
                    <DropButton
                        dropProps={{
                            align: { top: "top", right: "right" },
                            elevation: "xlarge",
                        }}
                        gap="xsmall"
                        label="Jump to"
                        plain
                        icon={<FontAwesomeIcon icon={faAngleDown} />}
                        dropContent={
                            <Box pad={{ vertical: "xsmall", horizontal: "small" }} gap="xsmall">
                                <Text>
                                    <Anchor size="small" href="#comprehensive" label="Comprehensive" />
                                </Text>
                                <Text>
                                    <Anchor size="small" href="#landmanagement" label="Land Management" />
                                </Text>
                                <Text>
                                    <Anchor size="small" href="#industries" label="Industries" />
                                </Text>
                            </Box>
                        }
                    />
                ) : (
                    <Box direction="row" justify="between" gap="small" align="end" alignSelf="end">
                        <Text size="xsmall">Jump to:</Text>
                        <Text>
                            <Anchor size="small" href="#comprehensive" label="Comprehensive" />
                        </Text>
                        <Text>
                            <Anchor size="small" href="#naturalhazards" label="Natural Hazards" />
                        </Text>
                        <Text>
                            <Anchor size="small" href="#landmanagement" label="Land Management" />
                        </Text>
                        <Text>
                            <Anchor size="small" href="#industries" label="Industries" />
                        </Text>
                    </Box>
                )}
            </Header>
            <Box overflow={{ vertical: "scroll" }} pad={{ vertical: "xsmall", horizontal: "small" }}>
                <div className="report-list">
                    <ReportListStandard cat="Comprehensive" anchor="comprehensive" />
                    <ReportListStandard cat="Natural Hazards" anchor="naturalhazards" />
                    <ReportListStandard cat="Land Management" anchor="landmanagement" />
                    <ReportListStandard cat="Industries" anchor="industries" />
                </div>
            </Box>
        </>
    );
};

export default Reports;
