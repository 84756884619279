import React, { useContext } from "react";
import appContext from "../context/appContext";
// import reports from "../assets/reports.json";
// import geoDict from "../assets/geoDict.json";
import { Box, Button, DropButton, Paragraph, Tip, Text } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";

const StyledDropButton = styled(DropButton)`
    padding: none;
    line-height: auto;
    svg {
        vertical-align: baseline;
    }
`;

const GeoLevelAlerts = () => {
    const { notifications } = useContext(appContext);
    if (!notifications || notifications.geoLevelAlerts.length === 0) return null;

    return (
        // <StyledDropButton
            
        //     dropAlign={{ top: "bottom", left: "right" }}
        //     size="small"
        //     color="#FFAA15"
        //     dropContent={
        //         <Box pad="small" background="dark-1">
        //             {notifications.geoLevelAlerts.map((alert, i) => (
        //                 <Paragraph key={i} size="small" margin="0">
        //                     {alert}
        //                 </Paragraph>
        //             ))}
        //         </Box>
        //     }
        // >
            
        //     DATA ALERT
        // </StyledDropButton>
        <Tip 
            dropProps={{trapFocus:"true"}}
            content={
                <Box>
                    {notifications.geoLevelAlerts.map((alert, i) => (
                        <Paragraph key={i} size="small" margin="0">
                            {alert}
                        </Paragraph>
                    ))}
                </Box>
            }
        >
            <Box style={{cursor:"pointer",minWidth:"fit-content"}} direction="row" align="center" gap="xxsmall" background={"#ffeccc"} border={{size:"xsmall", color:"#ffaa15"}} round="small" pad={{vertical:"2px",horizontal:"xsmall"}}>
                <FontAwesomeIcon icon={faExclamationTriangle} size="sm" color={"#8c5e0c"}/>
                <Text margin={{top:"2px"}} size="xsmall" color={"#8c5e0c"}>
                    Data Alert
                </Text>
            </Box>
        </Tip>
    );
};

const ReportLevelAlerts = ({ report }) => {
    const { notifications } = useContext(appContext);
    if (!notifications || notifications.reportLevel[report].alerts.length === 0) return null;

    return (
        // <StyledDropButton
        //     icon={<FontAwesomeIcon color="#FFAA15" icon={faExclamationTriangle} size="sm" />}
        //     dropAlign={{ top: "bottom", left: "right" }}
        //     plain
        //     size="small"
        //     dropContent={
        //         <Box pad="small" background="dark-1">
        //             {notifications.reportLevel[report].alerts.map((alert, i) => (
        //                 <Paragraph key={i} margin="0" size="small">
        //                     {alert}
        //                 </Paragraph>
        //             ))}
        //         </Box>
        //     }
        // />
        <Tip 
            dropProps={{trapFocus:"true"}}
            content={
                <Box>
                    {notifications.reportLevel[report].alerts.map((alert, i) => (
                        <Paragraph key={i} margin="0" size="small">
                            {alert}
                        </Paragraph>
                    ))}
                </Box>
            }
        >
            <Box style={{cursor:"pointer"}} direction="row" align="center" gap="xxsmall" background={"#ffeccc"} border={{size:"xsmall", color:"#ffaa15"}} round="small" pad={{vertical:"none",horizontal:"xsmall"}}>
                <FontAwesomeIcon icon={faExclamationTriangle} size="xs" color={"#8c5e0c"}/>
                <Text margin={{top:"2px"}} size="xsmall" color={"#8c5e0c"}>
                    Data Alert
                </Text>
            </Box>
        </Tip>
    );
};

export { GeoLevelAlerts, ReportLevelAlerts };
