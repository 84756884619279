function isObject(item) {
    return item && typeof item === "object" && !Array.isArray(item);
}

export function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return mergeDeep(target, ...sources);
}
export function internetExplorerCheck() {
    let ua = window.navigator.userAgent; //Check the userAgent property of the window.navigator object
    let msie = ua.indexOf("MSIE "); // IE 10 or older
    let trident = ua.indexOf("Trident/"); //IE 11
    if (msie > 0 || trident > 0){
        // since IE can't use the processData module, don't run it
        alert(
            "To view the content on this page, please use a modern browser such as Firefox, Chrome, Safari, or Microsoft Edge."
        );
        window.parent.location.href = "//headwaterseconomics.org/";
    }
}