import { useEffect, useRef, useReducer } from "react";
import { getData } from "../helpers/lambdaHelper";
import _merge from "lodash/merge";
import _isEqual from "lodash/isEqual";

const useQuickFactsData = ({ geoIDs }) => {
    const cache = useRef({});
    const initialState = {
        status: "idle",
        error: null,
        quickFactsData: {},
    };

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "FETCHING":
                if (state.status === "fetching") return state;
                return { ...initialState, ...state, status: "fetching" };
            case "FETCHED":
                if (state.status === "fetched" && _isEqual(state.quickFactsData, action.payload)) return state;
                return { ...initialState, ...state, status: "fetched", quickFactsData: action.payload };
            case "FETCH_ERROR":
                return { ...initialState, ...state, status: "error", error: action.payload };
            case "RESET":
                return initialState;
            default:
                return state;
        }
    }, initialState);
    useEffect(() => {
        if (!geoIDs || !geoIDs[0]) {
            dispatch({ type: "RESET" });
            return;
        }

        const fetchData = async () => {
            let missingIDs = new Set();
            let out = {};
            geoIDs.forEach((id) => {
                if (cache.current && cache.current[id]) {
                    out[id] = cache.current[id];
                } else {
                    missingIDs.add(id);
                }
            });
            if (missingIDs.size === 0) {
                let currentlyFetching = false;
                Object.keys(cache.current).forEach((id) => {
                    if (cache.current[id] === "fetching") {
                        currentlyFetching = true;
                    }
                });
                dispatch({ type: currentlyFetching ? "FETCHING" : "FETCHED", payload: out });
            } else {
                
                missingIDs.forEach((id) => (cache.current[id] = "fetching"));
                dispatch({ type: "FETCHING" });
                let errored = false;
                let newData;
                try {
                    newData = await getData("fetchEpsQuickFacts", {
                        ids: [...missingIDs],
                    });
                } catch (error) {
                    errored = true;
                    dispatch({ type: "FETCH_ERROR", payload: error.message });
                    throw error;
                }
                if (!errored){
                    // add the new recods to the cache
                    _merge(cache.current, newData);
                    dispatch({ type: "FETCHED", payload: _merge(out, newData) });
                }
                

            }
        };
        fetchData();
    }, [geoIDs]);

    return state;
};

export default useQuickFactsData;
