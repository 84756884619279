import React, { useState } from "react";

import ControlContainer from "./ControlContainer";
import styled from "styled-components";
import { MapContext } from "@urbica/react-map-gl";

const WarningBox = styled(ControlContainer)`
    background: #ffcc00;
    transition: all 0.25 ease;
    color: rgb(25, 25, 25);
    opacity: 0.9;
    font-size: 1.4em;
    ::before {
        content: "";
        position: absolute;
        display: block;
        width: 0px;
        left: 0;
        top: 50%;
        border: 10px solid transparent;
        border-left: 0;
        border-right: 10px solid #ffcc00;
        transform: translate(calc(-100% - 2px), -50%);
    }
    animation: bounce 2s ease-in-out .5s infinite;
`;

const zoomLevels = {
    place: { label: "cities or towns", value: 5 },
    county: { label: "counties", value: 2 },
    state: { label: "states", value: 2 },
    tract: { label: "cencus tracts", value: 3 },
    cd: { label: "congressional districts", value: 2 },
    tribal: { label: "American Indian Areas", value: 4 },
    portion: { label: "metro and rural portions of a state", value: 2 },
    csa: { label: "combined statistical areas", value: 3 },
    cousub: { label: "county subdivisions", value: 5 },
    nation: { label: "nation", value: 0 },
};

const ZoomInWarning = ({ activeMapLayer, zoomThreshhold, warningText, ...rest }) => {
    const [currentZoom, set_currentZoom] = useState();
    const onZoomEnd = (map) => {
        const newZoom = map.getZoom();
        if (newZoom) set_currentZoom(map.getZoom());
    };
    return (
        <>
            <MapContext.Consumer>
                {(map) => {
                    if (!currentZoom) {
                        map.on("zoomend", function () {
                            onZoomEnd(map);
                        });
                        onZoomEnd(map);
                    }
                    return;
                }}
            </MapContext.Consumer>
            {activeMapLayer && currentZoom && currentZoom < zoomLevels[activeMapLayer].value && (
                <WarningBox position="top-left" {...rest}>
                    Zoom in to see {zoomLevels[activeMapLayer].label}
                </WarningBox>
            )}
            {zoomThreshhold && warningText && currentZoom && currentZoom < zoomThreshhold && (
                <WarningBox position="top-left" {...rest}>
                    {warningText}
                </WarningBox>
            )}
        </>
    );
};

export default ZoomInWarning;
