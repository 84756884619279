import React, { useContext } from "react";
import { getRelativeHeadingSize } from "../../../grommet-themes/he-main";
import { useViewport } from "../../../components/ReactContext_Viewport";
import appContext from "../context/appContext";
import { Box, Text, Button, Anchor, Stack, Image, Layer } from "grommet";
import { SearchInput } from "../../../components/HeLocationPicker";
import coverPhoto from "../assets/images/startscreen2.jpg";
import AppMenu from "../../../components/AppMenu";
import heLogoIcon from "../assets/images/HE-LogoIconWhite.svg";
import ReactPlayer from "react-player";
import demoThumb from "../assets/images/demoThumb.jpg";
import { CirclePlay, FormClose } from "grommet-icons";

function StartScreen() {
    const { setSearchLocation } = useContext(appContext);
    const { screenSize, appWidth } = useViewport();

    const [show, setShow] = React.useState();

    return (
        <Box
            fill
            background={{ size: "cover", image: "url(" + coverPhoto + ")", color: "rgba(20,20,20,.3)" }}
            className="home-screen"
            height={{ min: "100vh" }}
        >
            <Box fill background="rgba(20,20,20,.25)">
                <Box justify="between" direction="row" align="center" height={{ min: "fit-content" }}>
                    <Box direction="row" align="center" pad="xsmall">
                        <Box
                            border={{ side: "right", color: "light-3", size: "xsmall" }}
                            pad={{ right: "6px", left: "3px" }}
                            align="center"
                            width={{ min: "fit-content" }}
                        >
                            <Button plain href="/">
                                <img
                                    className="logo-icon"
                                    height={screenSize === "mobile" ? "30px" : "45px"}
                                    width={screenSize === "mobile" ? "30px" : "45px"}
                                    src={heLogoIcon}
                                    alt="Headwaters logo"
                                />
                            </Button>
                        </Box>
                        <Box
                            direction="row"
                            pad={{ left: "4px", right: "small" }}
                            margin={{ bottom: "-2px" }}
                            width={{ min: "fit-content" }}
                        >
                            <Button plain href="/apps/economic-profile-system">
                                <Text size={screenSize === "mobile" ? "large" : "xxlarge"} color="#fff">
                                    <Text size={screenSize === "mobile" ? "large" : "xxlarge"} weight="bold">
                                        Economic
                                    </Text>{" "}
                                    Profile System
                                </Text>
                            </Button>
                        </Box>
                    </Box>
                    <Box
                        background="light-1"
                        margin="xsmall"
                        elevation="small"
                        width="45px"
                        height="45px"
                        align="center"
                        justify="center"
                        round="100%"
                    >
                        <AppMenu
                            customItems={[
                                {
                                    label: "About the Economic Profile System",
                                    href: "/tools/economic-profile-system/about-eps/",
                                },
                            ]}
                        />
                    </Box>
                </Box>
                <Box fill direction="column" align="center" justify="center">
                    <Box width="xlarge" pad="small" margin={{ top: appWidth < 1000 ? "large" : "-10%" }}>
                        <Text
                            style={{ textShadow: "0 0  3px #00000088" }}
                            margin={{ bottom: "small" }}
                            weight="bold"
                            textAlign="start"
                            color="#fff"
                            size={getRelativeHeadingSize("xxxlarge", screenSize)}
                            className="condensed"
                        >
                            Get socioeconomic profiles
                        </Text>
                        {/* <Box background={"brand"} direction="row" round="small" gap="xsmall" pad={{vertical:"xsmall",horizontal:"small"}} margin={{bottom:"small"}}>
                            <Text weight={"bolder"}>NEW!</Text> <Text>The latest American Community Survey data are now available in EPS!</Text>
                        </Box> */}
                        <Box pad={"none"} background="white">
                            <SearchInput
                                screenSize={screenSize}
                                setSearchLocation={setSearchLocation}
                                placeholder={"Search by community, Indigenous area, county, or state"}
                                geoTypes={"he-place,he-county,he-state,he-nation,he-tribal"}
                            />
                            
                        </Box>
                        <Box
                        margin={{ vertical: "medium" }}
                        pad="small"
                        background="rgba(225,225,225,.95)"
                        direction={screenSize === "mobile" ? "column" : "row"}
                        gap="small"
                        align="center"
                        width={screenSize === "mobile" ? "" : "medium"}
                        alignSelf="center"
                        round="xsmall"
                        elevation="small"
                        animation="fadeIn"
                        onClick={() => setShow(true)}
                    >
                        <Anchor>
                            <Box
                                round="xxsmall"
                                width="small"
                                overflow="hidden"
                                border={{ size: "small", color: "light-1" }}
                            >
                                <Stack anchor="center">
                                    <Image src={demoThumb} fill />
                                    <CirclePlay />
                                </Stack>
                            </Box>
                        </Anchor>
                        <Text color="dark-1" weight="bold">
                            How to use the Economic Profile System
                        </Text>
                        {show && (
                            <Layer
                                onEsc={() => setShow(false)}
                                onClickOutside={() => setShow(false)}
                                background="dark-1"
                            >
                                <Box round="xsmall" overflow="hidden" background="dark-1" width={{min: "fit-content"}}>
                                    <Box direction="row" justify="between" pad="small">
                                        <Text>How to use the Economic Profile System</Text>
                                        <Button
                                            onClick={(event) => {
                                                setShow(false);
                                                event.stopPropagation();
                                            }}
                                            plain
                                            label={<FormClose />}
                                        />
                                    </Box>
                                    <ReactPlayer
                                        controls={true}
                                        url="https://vimeo.com/headecon/eps"
                                    />
                                </Box>
                            </Layer>
                        )}
                    </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
export default StartScreen;
