import React, { useContext } from "react";
import appContext from "../context/appContext";
import { Box, DropButton, Paragraph } from "grommet";
import { Edit } from "grommet-icons";
import { SearchInput } from "../../../components/HeLocationPicker";

const EditBenchmarkButton = () => {
    const { benchmarkGeo, setBenchmarkGeo } = useContext(appContext);

    return (
        <DropButton
            a11yTitle="Edit the compasison geography"
            icon={<Edit color="light-1" size="small" margin={{ top: "-5px" }} />}
            dropContent={
                <Box
                    pad="small"
                    background="dark-1"
                    border={{
                        color: "light-3",
                        size: "xsmall",
                        side: "all",
                    }}
                    
                >
                    <Paragraph>Edit the comparison location. You can select a state, county, American Indian area, or the United States.</Paragraph>
                    <SearchInput
                        setSearchLocation={(newVal) => {
                            setBenchmarkGeo(newVal);
                        }}
                        placeholder={benchmarkGeo.label}
                        geoTypes={"he-county,he-state,he-nation,he-tribal,he-portion"}
                    />
                </Box>
            }
            dropProps={{ align: { bottom: "top", right: "left" } }}
        />
    );
};

export default EditBenchmarkButton;
