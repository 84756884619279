const colors = {
    brand: "#0D4D80",
    brandLight: "#6580AA",
    accent: "#820915",
    error: "#FF4040",
    warning: "#FFAA15",
    geos_defaultBorder: "rgb(227, 225, 179)",
    geos_focusBorder: "rgb(227, 225, 179)",
    geos_focusFill: "rgba(227, 225, 179, 0.12)",
    geos_selectBorder: "rgb(127, 181, 225)",
    geos_selectFill: "rgba(61, 113, 153, 0.6)",
    geos_barFill: "rgba(61, 113, 153, 1)",
};

export default colors;
