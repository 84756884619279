import React, { useState, useEffect, useContext, useRef } from "react";

import HeMapboxUrbica from "../../../../components/HeMapboxUrbica/HeMapboxUrbica";
import appContext from "../../context/appContext";
import { getData } from "../../../../helpers/lambdaHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerPlus } from "@fortawesome/pro-regular-svg-icons";
import { Box, Anchor, Stack } from "grommet";
import { Layer as MapLayer, Source as MapSource } from "@urbica/react-map-gl";
import _groupBy from "lodash/groupBy";
import colors from "../../assets/colors";

const mapboxID = "headmin.he-census-2022-e";

const StaticMap = () => {
    const { selectedLocations, setIsMultipleGeosViewVisible } = useContext(appContext);
    const [mapExtent, set_mapExtent] = useState({ minLat: 25, maxLat: 50, minLon: -127, maxLon: -65 });
    const mapRef = useRef();

    useEffect(() => {
        if (selectedLocations.length === 0) return;

        // zoom to new location
        getData("fetchExtentOfGeos", {
            ids: selectedLocations[0].id,
        }).then((result) => {
            set_mapExtent(result);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocations]);

    return (
        <Box ref={mapRef} fill id="map-container" background="#777">
            <Stack fill>
                <Box fill>
                    <HeMapboxUrbica
                        extentPadding={10}
                        // styleJson={mapStyleJsonDark}
                        mapStyle="mapbox://styles/mapbox/dark-v10"
                        extent={mapExtent}
                        // customLayerStyles={customLayerStyles}
                        isInteractive={false}
                        attributionSide={"right"}
                    >
                        <MapLayers />
                    </HeMapboxUrbica>
                </Box>
                <Box fill align="center" background="rgba(1,10,18,.5)" justify="center">
                    <Box>
                        <Anchor
                            color="#fff"
                            onClick={() => {
                                setIsMultipleGeosViewVisible(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faMapMarkerPlus} />
                            &nbsp;add more places
                        </Anchor>
                    </Box>
                </Box>
            </Stack>
        </Box>
    );
};

const MapLayers = () => {
    const { selectedLocations } = useContext(appContext);
    const currentLayers = _groupBy(selectedLocations, (sl) => sl.geo_level);
    return (
        <>
            <MapSource id={"censusSrc"} type="vector" url={"mapbox://" + mapboxID} />
            {Object.keys(currentLayers).map((geoType) => {
                return (
                    <MapLayer
                        key={geoType + "_selected"}
                        id={geoType + "_selected"}
                        source-layer={geoType}
                        before="waterway-label"
                        source="censusSrc"
                        type= "line"
                        paint= {{ "line-color": colors.geos_selectBorder, "line-width": 2 }}
                        filter={["in", ["id"], ...currentLayers[geoType].map((e) => e.id)]}
                    />
                );
            })}
        </>
    );
};
export default StaticMap;
