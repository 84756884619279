import React from "react";
import PropTypes from "prop-types";
import { Menu, Nav } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-regular-svg-icons";

function AppMenu({ customItems }) {
    let items = [{ label: "Headwaters Economics", href: "/" }];
    if (customItems) {
        customItems.forEach((item) => {
            items.push(item);
        });
    }
    items.push({ label: "More tools", href: "/tools/" });

    return (
        <Nav>
            <Menu
                dropProps={{
                    align: { top: "bottom", right: "left" },
                    elevation: "xlarge",
                }}
                icon={<FontAwesomeIcon size="lg" color="#333" icon={faBars} />}

                justifyContent="end"
                items={items}
                dropBackground="#333"
            />
        </Nav>
    );
}

AppMenu.propTypes = {
    /** Custom menu items. By default, the root of our site will be first and the tools page will be last. */
    customItems: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, href: PropTypes.string })),
};

export default AppMenu;
