import React from "react";

const MapboxWordmark = ({ attributionSide }) => {
    return (
        <a
            href="http://mapbox.com/about/maps"
            className="HeMapboxUrbica-mapbox-wordmark"
            target="_blank"
            rel="noopener noreferrer"
            style={attributionSide === "left" ? { left: 10 } : { right: 10 }}
        >
            Mapbox
        </a>
    );
};

export default MapboxWordmark;