import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';

import App from /***APP_PATH***/'./apps-standalone/economic-profile-system/App'/***APP_PATH***/; 

//validate that the user in not using internet explorer
import { internetExplorerCheck } from './helpers/he-general';
internetExplorerCheck();


ReactDOM.render(<App />, document.getElementById('app-root-'+/***APP_NAME***/'economic-profile-system'/***APP_NAME***/));