import React, { useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";
import AppProvider from "./context/AppProvider";
import appContext from "./context/appContext";
import { ViewportProvider, useViewport } from "../../components/ReactContext_Viewport";
import { Grommet, Box } from "grommet";
import HeMainTheme from "../../grommet-themes/he-main";
import { deepMerge } from "grommet/utils";
import StartScreen from "./screens/StartScreen";
import MainScreen from "./screens/MainScreen";
import colors from "./assets/colors";
// import FeedbackModal from "./components/FeedbackModal";

import GoogleAnalytics from "../../components/GoogleAnalyticsWithRouter";

const epsExplore = deepMerge(HeMainTheme, {
    global: {
        colors: {
            brand: colors.brand,
            brandLight: colors.brandLight,
            accent: colors.accent,
            error: colors.error,
            warning: colors.warning,
        },
        font: {
            family: "museo-sans",
            size: "18px",
            height: "20px",
            color: "#222",
        },
    },
});

function App() {
    return (
        <Router basename={"/apps/economic-profile-system"}>
            <Routed />
        </Router>
    );
}

//Split out so Storybook can use a different type of router
function Routed({ storybookState }) {
    return (
        <AppProvider storybookState={storybookState}>
            <ViewportProvider>
                <GrommetContainer />
            </ViewportProvider>
        </AppProvider>
    );
}

const GrommetContainer = () => {
    const { appWidth } = useViewport();
    const { grommetRoot } = useContext(appContext);
    return (
        <Grommet className="app" full={appWidth < 1000 ? false : true} theme={epsExplore}>
            <GoogleAnalytics />
            <Box fill ref={grommetRoot}>
                {/* <FeedbackModal /> */}
                <Switch>
                    <Route
                        path="/"
                        exact
                        component={StartScreen}
                    ></Route>
                    <Route
                        path="/:urlIDs"
                        component={MainScreen}
                    ></Route>
                </Switch>
            </Box>
        </Grommet>
    );
};
export { Routed };

export default App;
