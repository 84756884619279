import { detect } from "detect-browser";
import { getReportsApiRoot } from "./lambdaHelper";

class ReportGenerationError extends Error {
    constructor(message) {
        super(message); // (1)
        this.name = "ReportGenerationError";
    }
}
class InvalidTokenError extends Error {
    constructor(message) {
        super(message); // (1)
        this.name = "InvalidTokenError";
    }
}

function downloadReport(inputData, options) {
    //add the user/browser details for the log
    const browser = detect();
    inputData["user_referrer"] = document.location.href;
    inputData["user_browser"] = browser.name;
    inputData["user_browser_version"] = browser.version;

    const requestData = [
        {
            name: "json_input",
            value: JSON.stringify(inputData),
        },
        {
            name: "auth_token",
            value:
                options && "auth_key" in options
                    ? options.auth_key //"58f0d42d-0ed7-493d-afe4-f042d5c3aac3",
                    : "",
        },
    ];

    const handleResponseStatusErrors = (response) => {
        if (!response.ok) {
            if (response.status === 401) {
                throw new InvalidTokenError(
                    "Invalid Token (status:" + response.status + " ). " + JSON.stringify(inputData)
                );
            } else {
                throw new ReportGenerationError(
                    "Error generating report (status:" + response.status + " ). " + JSON.stringify(inputData)
                );
            }
        }
        return response;
    };

    const handleResponseTypeErrors = (data) => {
        if (!data.type.startsWith("application")) {
            const e = new ReportGenerationError(
                "Error generating report (response type is not a file). " + JSON.stringify(inputData)
            );
            throw e;
        }
        return data;
    };

    const apiPath = getReportsApiRoot() + "/epsReport";
    let outputFileName = "out"; // default output file name
    return new Promise(function (resolve, reject) {
        function postData(url = apiPath, data = requestData) {
            return fetch(url, {
                method: "POST",
                headers: new Headers({
                    Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    // "Content-Type": "application/json; charset=utf-8",
                }),
                body: JSON.stringify(data),
            })
                .then(handleResponseStatusErrors)
                .then((response) => {
                    outputFileName = response.headers.get("Content-Disposition").split("filename=")[1]; // get the filename from the response header
                    return response.blob();
                });
        }

        postData()
            .then(handleResponseTypeErrors)
            .then((blob) => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = outputFileName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
                resolve();
            })
            .catch((e) => {
                // resolve the promise
                reject(e);
                // then throw the error for logging in sentry
                throw e;
            });
    });
}

export { downloadReport };
