import { useEffect, useRef, useReducer } from "react";
import { getData } from "../helpers/lambdaHelper";

const useSearchData = ({ geoTypes, limit, nameLike, searchCache, year }) => {
    const cache = useRef(searchCache || {});

    const initialState = {
        status: "idle",
        error: null,
        suggestions: [],
    };

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case "FETCHING":
                return { ...initialState, status: "fetching" };
            case "FETCHED":
                return { ...initialState, status: "fetched", suggestions: action.payload };
            case "FETCH_ERROR":
                return { ...initialState, status: "error", error: action.payload };
            case "RESET":
                return initialState;
            default:
                return state;
        }
    }, initialState);

    useEffect(() => {

        if (!geoTypes || !limit || !nameLike) {
            dispatch({ type: "RESET" });
            return;
        }

        const fetchData = async () => {
            dispatch({ type: "FETCHING" });
            if (cache.current && cache.current[geoTypes] && cache.current[geoTypes][limit] && cache.current[geoTypes][limit][nameLike]) {
                const suggestions = cache.current[geoTypes][limit][nameLike];
                dispatch({ type: "FETCHED", payload: suggestions });
            } else {
                if (!cache.current[geoTypes]) {
                    cache.current[geoTypes] = {};
                }
                if (!cache.current[geoTypes][limit]) {
                    cache.current[geoTypes][limit] = {};
                }
                cache.current[geoTypes][limit][nameLike] = "fetching";
                try {
                    const suggestions = await getData("fetchSearchData", {
                        geoTypes: geoTypes || "he-place,he-county,he-state",
                        limit: limit,
                        nameLike: nameLike,
                        year: year
                    });
                    cache.current[geoTypes][limit][nameLike] = suggestions;
                    dispatch({ type: "FETCHED", payload: suggestions });
                } catch (error) {
                    dispatch({ type: "FETCH_ERROR", payload: error.message });
                    throw error;
                }
            }
        };

        fetchData();

    }, [geoTypes, limit, nameLike, year]);

    return state;
};

export default useSearchData;
