import { useCallback, useEffect, useRef } from "react";
import throttle from "lodash/throttle";

function useThrottle(callback, delay) {
    // Memoizing the callback because if it's an arrow function
    // it would be different on each render
    const memoizedCallback = useCallback(callback, [callback]);
    const throttledFn = useRef(throttle(memoizedCallback, delay));

    useEffect(() => {
        throttledFn.current = throttle(memoizedCallback, delay);
    }, [memoizedCallback, throttledFn, delay]);

    return throttledFn.current;
}
export default useThrottle;
