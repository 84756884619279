import React from "react";
import PropTypes from 'prop-types';
import { Box, Button, Footer, Image } from "grommet";
import HeLogo from "./HE_Logo_Horizontal_Color.svg";

function AppFooter(props) {
    return (
        <Footer {...props} pad={{ vertical: "none", horizontal: "xsmall" }}>
            <Box align="start">{props.leftSideContent}</Box>
            <Box fill width={{max:'300px'}}>
                <Button plain href='/' fill='vertical'>
                    <Image align='center' fill='vertical' src={HeLogo} />
                </Button>
            </Box>
        </Footer>
    );
}

AppFooter.propTypes = {
    leftSideContent:PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

export default AppFooter;
