import React, { useCallback, useEffect, useRef, useState } from "react";

import { useResizeDetector } from "react-resize-detector";
import PropTypes from "prop-types";

import MapGL from "@urbica/react-map-gl";
// import ReactTooltip from "react-tooltip";

import WebMercatorViewport from "@math.gl/web-mercator";
import "./HeMapboxUrbica.scss";
import "mapbox-gl/dist/mapbox-gl.css";
// import throttle from "lodash/throttle";

// custom components
// import Tooltip from "./components/Popup";
import Attribution from "./components/Attribution";
import MapboxWordmark from "./components/MapboxWordmark";
import Logo from "./components/Logo";
import NavigationControl from "./components/NavigationControl";

import useExtentOfGeos from "../../hooks/useExtentOfGeos";

import { getMergedExtent, extentsEqual } from "./ExtentHelperFunctions";
const HeMapboxUrbica = (props) => {
    const { width, height, ref } = useResizeDetector();
    return (
        <div style={{ overflow: "hidden", height: "100%", width: "100%" }} ref={ref}>
            {width && height && <MapComponent {...props} height={height} width={width} />}
        </div>
    );
};

const mapboxApiAccessToken =
    "pk.eyJ1IjoiaGVhZG1pbiIsImEiOiJjaXQwOXUyY2wwbGdlMnpwZ2huMTNwb3VnIn0.e2ya7bTd2eFWlS4kSvTJVQ";
// const layerTypes = ["default", "select", "highlight", "click", "hover"];

// let editTimer = null;

const MapComponent = ({
    width,  // from useResizeDetector
    height, // from useResizeDetector
    extent,
    extentPadding,
    pitch,
    bearing,
    interactiveLayer,
    popupContent,
    // popupTrigger,
    cursor_default,
    cursor_isDragging,
    cursor_isHovering,
    onExtentChange,
    zoomInWarningScale,
    zoomInWarningText,
    onMapLoad,
    onMapIdle,
    onMapRender,
    isInteractive,
    legend,
    logo,
    attributionSide,
    // clickRadius,
    containerRef,
    tooltipPortal,
    isHovering,
    zoomGeoIds,
    enableExport,
    children,
    ...OtherMapGLProps
}) => {
    const mapRef = useRef();
    // const zoomInWarningTooltip = useRef();
    const [viewport, set_viewport] = useState(null);

    const { extentOfGeos: extentOfZoomGeoIds } = useExtentOfGeos({
        geoIDs: zoomGeoIds,
    });
    const [currentExtent, set_currentExtent] = useState(null);

    const [state, set_state] = useState({
        popupSpecs: null,
        mapLoaded: false,
        // selectedEditMode: EditorModes.READ_ONLY,
        editFeatures: [],
        selectedEditFeatureId: null,
        doubleClickZoom: true,
        onClickTimeout: null,
        isCursorOverWithinOverlayElement: false,
        isDragging: false,
        isHovering: false,
    });

    const zoomToExtent = useCallback(
        (extentObject) => {
            const vp = new WebMercatorViewport({ width: width, height: height });
            const { longitude, latitude, zoom } = vp.fitBounds(
                [
                    [extentObject.minLon, extentObject.minLat],
                    [extentObject.maxLon, extentObject.maxLat],
                ],
                { minExtent:0, padding: { top: extentPadding, left: extentPadding, bottom: extentPadding, right: extentPadding } }
            );
            const newVp = {
                ...vp,
                longitude,
                latitude,
                zoom,
            };
            set_viewport(newVp);
        },

        [extentPadding, width, height] //state.mapLoaded
    );
    
    useEffect(()=>{
        if (extent && !viewport) zoomToExtent(extent);
    }, [zoomToExtent, extent, viewport]);

    useEffect(
        () => {
            if (!extentOfZoomGeoIds) return;
            const newExtent = currentExtent
                ? getMergedExtent([currentExtent, extentOfZoomGeoIds])
                : extentOfZoomGeoIds;
            if (!extentsEqual(currentExtent, newExtent)) zoomToExtent(extentOfZoomGeoIds);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [extentOfZoomGeoIds, zoomToExtent]
    );

    const onViewportChange = useCallback(
        (updatedViewport) => {
            set_viewport({ ...viewport, ...updatedViewport });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [viewport]
    );

    const resize = useCallback(() => {
        if (!state.mapLoaded) return;
        if (viewport.width === width && viewport.height === height) return;
        onViewportChange({
            width: width,
            height: height,
        });
    }, [onViewportChange, width, height, viewport, state.mapLoaded]);

    const handleExtentChange = useCallback(() => {

        if (!mapRef.current) return;
        const map = mapRef.current.getMap();
        const newBounds = map.getBounds();
        const newExtent = {
            minLat: newBounds._sw.lat,
            minLon: newBounds._sw.lng,
            maxLat: newBounds._ne.lat,
            maxLon: newBounds._ne.lng,
        };
        set_currentExtent(newExtent);
        if (onExtentChange) {
            onExtentChange(newExtent);
        }
    }, [onExtentChange]);

    useEffect(() => {
        zoomToExtent(extent);
    }, [extent, zoomToExtent]);

    // useEffect(
    //     (e) => {
    //         handleExtentChange(e);
    //     },

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    //     [viewport]
    // );

    useEffect(resize, [resize]);

    // useEffect(() => {
    //     if (!state.clickedFeature) return;
    //     onFeatureClick(state.clickedFeature.properties);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [state.clickedFeature]);

    // const getCursor = ({ isHovering, isDragging }) => {
    //     let cursor = cursor_default;
    //     if (isHovering) {
    //         cursor = cursor_isHovering;
    //     }
    //     if (isDragging) {
    //         cursor = cursor_isDragging;
    //     }
    //     return cursor;
    // };

    const onLoad = () => {
        if (state.mapLoaded) return; // this appears to be running twice with Urbica library
        set_state((s) => ({ ...s, mapLoaded: true }));
        if (onMapLoad) {
            onMapLoad(mapRef);
        }
        const map = mapRef.current.getMap();

        if (onMapIdle) {
            map.on("idle", onMapIdle);
        }
        if (onMapRender) {
            map.on("render", onMapRender);
        }
        
        map.on("zoomend", handleExtentChange);
    };

    const setDragging = () => {
        set_state((s) => ({ ...s, isDragging: true }));
    };
    const setNotDragging = () => {
        set_state((s) => ({ ...s, isDragging: false }));
    };

    if (!viewport) return null;
    return (
        <MapGL
            className="HeMapboxUrbica"
            style={{ width: width, height: height }}
            pitch={pitch}
            bearing={bearing}
            accessToken={mapboxApiAccessToken}
            latitude={viewport.latitude}
            longitude={viewport.longitude}
            zoom={viewport.zoom}
            onViewportChange={isInteractive ? set_viewport : null}
            attributionControl={false}
            onLoad={onLoad}
            ref={mapRef}
            viewportChangeMethod="easeTo"
            onDragstart={setDragging}
            onDragend={setNotDragging}
            preserveDrawingBuffer={enableExport}
            cursorStyle={state.isDragging ? cursor_isDragging : isHovering ? cursor_isHovering : cursor_default}
            {...OtherMapGLProps}
        >
            {children}
            {isInteractive && <NavigationControl />}

            {/* <Tooltip
                interactiveLayer={interactiveLayer}
                popupSpecs={state.popupSpecs}
                isCursorOverWithinOverlayElement={state.isCursorOverWithinOverlayElement}
                popupContent={popupContent}
                popupTrigger={"hover"}
                handleForcePopupClose={handleForcePopupClose}
                containerRef={containerRef}
                tooltipPortal={tooltipPortal}
            /> */}
            <Logo logo={logo} />
            <MapboxWordmark attributionSide={attributionSide} />
            <Attribution attributionSide={attributionSide} />
        </MapGL>
    );
};

MapComponent.propTypes = {
    /**
     * Initial extent. Defaults to the US.
     * Also can be use to update the extent dynamically.
     * To be use instead of latitude, longitude and zoom as designed in rect-map-gl
     * */
    extent: PropTypes.shape({
        minLat: PropTypes.number,
        minLon: PropTypes.number,
        maxLat: PropTypes.number,
        maxLon: PropTypes.number,
    }),

    /** The number of pixels to pad the extent when zooming to a defined extent */
    extentPadding: PropTypes.number,

    /** Function to run when a map loads. */
    onMapLoad: PropTypes.func,

    /** default css cursor style when over map*/
    cursor_default: PropTypes.string,
    /** css cursor style when over map is being dragged*/
    cursor_isDragging: PropTypes.string,
    /** css cursor style when feature is being hovered*/
    cursor_isHovering: PropTypes.string,

    /** Function to run when the extent changes */
    onExtentChange: PropTypes.func,

    /** Function to run when the map is idle */
    onMapIdle: PropTypes.func,

    /** Function to run when the map is rendered */
    onMapRender: PropTypes.func,

    /** Warning to display next to the zoom in button */
    zoomInWarningText: PropTypes.string,

    /** Scale at which to display `zoomInWarningText` */
    zoomInWarningScale: PropTypes.number,

    /** Should the user be allowed to interact with the map (i.e. zoom and pan)? */
    isInteractive: PropTypes.bool,

    isHovering: PropTypes.bool,

    /** Side of map to place mapbox logo and attribution */
    attributionSide: PropTypes.oneOf(["left", "right"]),

    tooltipPortal: PropTypes.object,

    legend: PropTypes.element,

    /** Provide if you want to update the extent based on the list of selected geographies.   */
    zoomGeoIds: PropTypes.arrayOf(PropTypes.string),

    enableExport: PropTypes.bool
};

MapComponent.defaultProps = {
    extent: { minLat: 25, maxLat: 50, minLon: -127, maxLon: -65 },
    pitch: 0,
    bearing: 0,
    interactiveLayer: null,
    popupContent: null,
    popupTrigger: "hover",
    cursor_default: "default",
    cursor_isDragging: "grabbing",
    cursor_isHovering: "pointer",
    onMapLoad: () => {},
    zoomInWarningText: "Zoom in",
    zoomInWarningScale: 0,
    isInteractive: true,
    isHovering: false,
    extentPadding: 40,
    attributionSide: "left",
    selectedGeos: null,
    enableExport:false
};

export default HeMapboxUrbica;
export { MapComponent };
