import { useEffect, useReducer } from "react";
import { getData } from "../helpers/lambdaHelper";
import _isEqual from "lodash/isEqual";

const useAcsYear = () => {

  const initialState = {
    status: "idle",
    error: null,
    acsYear: null,
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "FETCHING":
        if (state.status === "fetching") return state;
        return { ...state, status: "fetching" };
      case "FETCHED":
        if (
          state.status === "fetched" &&
          _isEqual(state.acsYear, action.payload)
        )
          return state;
        return { ...state, status: "fetched", acsYear: action.payload };
      case "FETCH_ERROR":
        return { ...state, status: "error", error: action.payload };
      case "RESET":
        return initialState;
      default:
        return state;
    }
  }, initialState);

  useEffect(() => {
    const fetchData = async () => {
      dispatch({ type: "FETCHING" });
      try {
        const newData = await getData("fetchAcsYear", {});
        dispatch({ type: "FETCHED", payload: newData });
      } catch (error) {
        dispatch({ type: "FETCH_ERROR", payload: error.message });
        throw error;
      }
    };

    fetchData();
  }, []);

  return state;
};

export default useAcsYear;
