import React, { useState } from "react";

import { Source, Layer, FeatureState, Filter } from "@urbica/react-map-gl";
import PropTypes from "prop-types";
import groupBy from "lodash/groupBy";
// import _uniqueId from "lodash/uniqueId";

const mapboxID = "headmin.he-census-2022-e";

const labelZoomLevel = {
    place: 7,
    county: 6,
    state: 4,
    tract: 7,
    cd: 4,
    tribal: 6,
    portion: 5,
    csa: 5,
    cousub: 7,
    nation: 2,
};

const CensusLayer = ({
    layerName,
    onHover,
    onClick,
    selectedFeatures,
    before,
    color_default_border,
    color_default_fill,
    color_hover_border,
    color_hover_fill,
    color_select_border,
    color_select_fill,
    color_highlight_border,
    color_highlight_fill,
    color_label,
    color_label_halo,
    filterIds,
    before_hoverLayer,
    lineWidth_defaultLayer,
    lineWidth_hoverLayer,
    lineWidth_selectLayer,
    disabled,
    uniqId,
    suppressLabels,
    highlightFeature,
}) => {
    const [hoveredEventState, set_hoveredEventState] = useState(null);

    const onLyrHover = (event, geoLevel) => {
        if (geoLevel !== layerName || disabled) return;
        if (event.features.length === 0) return;

        const ftr = event.features[0];
        if (!hoveredEventState || hoveredEventState.features[0].id !== event.features[0].id) {
            set_hoveredEventState(event);
            ftr.id = ftr.id.toString();
            if (onHover) onHover(ftr);
        }
    };

    const onLyrClick = (event, geoLevel) => {
        if (geoLevel !== layerName || disabled) return;
        if (event.features.length === 0) return;
        if (onClick) {
            const ftr = event.features[0];
            if (geoLevel === "portion") {
                ftr.id = -1 * ftr.id;
            }
            ftr.id = ftr.id.toString();
            onClick(ftr,event);
        }
    };

    const onLyrLeave = (event, geoLevel) => {
        if (geoLevel !== layerName || disabled) return;
        if (hoveredEventState) {
            set_hoveredEventState(null);
            if (onHover) onHover(null);
        }
    };

    const selectedFeaturesByType = groupBy(selectedFeatures, "geo_level");
    return (
        <>
            <Source id={"censusSrc_" + uniqId} type="vector" url={"mapbox://" + mapboxID} />
            <Layer
                id={layerName + "_default_" + uniqId}
                source-layer={layerName}
                before={before}
                source={"censusSrc_" + uniqId}
                type="fill"
                paint={{
                    "fill-color": color_default_fill,
                    "fill-outline-color": color_default_border,
                }}
                onHover={(evt) => {
                    onLyrHover(evt, layerName);
                }}
                onLeave={(evt) => {
                    onLyrLeave(evt, layerName);
                }}
                onClick={(evt) => {
                    onLyrClick(evt, layerName);
                }}
            />
            {lineWidth_defaultLayer > 1 && (
                <Layer
                    id={layerName + "_default_border_" + uniqId}
                    source-layer={layerName}
                    before={before}
                    source={"censusSrc_" + uniqId}
                    type="line"
                    paint={{
                        "line-color": color_default_border,
                        "line-width": lineWidth_defaultLayer,
                    }}
                />
            )}
            {filterIds && (
                <Filter
                    filter={["any", ...filterIds.map((idStr) => ["==", ["id"], parseInt(idStr, 10)])]}
                    layerId={layerName + "_default_" + uniqId}
                />
            )}
            {selectedFeatures &&
                selectedFeatures.length > 0 &&
                Object.keys(selectedFeaturesByType).map((geo_level,i) => {
                    const selectFilter = [
                        "any",
                        ...selectedFeaturesByType[geo_level].map((selectedFeature) => [
                            "==",
                            ["id"],
                            parseInt(selectedFeature.id * (geo_level === "portion" ? -1 : 1), 10),
                        ]),
                    ];
                    return (
                        <React.Fragment key={i}>
                            <Layer
                                id={geo_level + "_select_" + uniqId}
                                source-layer={geo_level}
                                before={before}
                                source={"censusSrc_" + uniqId}
                                type="fill"
                                paint={{
                                    "fill-color": color_select_fill,
                                    "fill-outline-color": color_select_border,
                                }}
                                filter={selectFilter}
                            />
                            {lineWidth_selectLayer > 1 && (
                                <Layer
                                    id={geo_level + "_select_border_" + uniqId}
                                    source-layer={geo_level}
                                    before={before}
                                    source={"censusSrc_" + uniqId}
                                    type="line"
                                    paint={{
                                        "line-color": color_select_border,
                                        "line-width": lineWidth_selectLayer,
                                    }}
                                    filter={selectFilter}
                                />
                            )}
                            {!suppressLabels && (
                                <Layer
                                    id={geo_level + "_select_label_" + uniqId}
                                    source-layer={geo_level + "-labels"}
                                    // before={before}
                                    source={"censusSrc_" + uniqId}
                                    type="symbol"
                                    minzoom={labelZoomLevel[geo_level]}
                                    layout={{
                                        "text-allow-overlap": false,
                                        "text-field": "{name}",
                                        // "text-transform": "uppercase",
                                        "text-letter-spacing": 0.1,
                                        "text-max-width": 10,
                                        "text-font": ["DIN Offc Pro Bold", "Arial Unicode MS Bold"],
                                        "text-padding": 2,
                                        "text-size": 12,
                                    }}
                                    paint={{
                                        "text-color": color_label,
                                        "text-halo-color": color_label_halo,
                                        // "text-halo-blur": 1,
                                        "text-halo-width": 1,
                                    }}
                                    filter={selectFilter}
                                />
                            )}
                        </React.Fragment>
                    );
                })}
            {!disabled && (
                <Layer
                    id={layerName + "_hoverFill_" + uniqId}
                    source-layer={layerName}
                    before={before}
                    source={"censusSrc_" + uniqId}
                    type="fill"
                    paint={{
                        "fill-color": [
                            "case",
                            ["boolean", ["feature-state", "hover"], false],
                            color_hover_fill,
                            "transparent",
                        ],
                        "fill-outline-color": "transparent",
                    }}
                />
            )}
            {!disabled && (
                <Layer
                    id={layerName + "_hoverBorder_" + uniqId}
                    source-layer={layerName}
                    before={before_hoverLayer || before}
                    source={"censusSrc_" + uniqId}
                    type="line"
                    paint={{
                        "line-color": [
                            "case",
                            ["boolean", ["feature-state", "hover"], false],
                            color_hover_border,
                            "transparent",
                        ],
                        "line-width": lineWidth_hoverLayer,
                    }}
                />
            )}
            {highlightFeature && (
                <Layer
                    id={layerName + "_highlightFill_" + uniqId}
                    source-layer={highlightFeature.geo_level}
                    before={before}
                    source={"censusSrc_" + uniqId}
                    type="fill"
                    paint={{
                        "fill-color": color_highlight_fill,
                        "fill-outline-color": "transparent",
                    }}
                    filter={["==", ["id"], parseInt(highlightFeature.id, 10)]}
                />
            )}
            {highlightFeature && (
                <Layer
                    id={layerName + "_highlightBorder_" + uniqId}
                    source-layer={highlightFeature.geo_level}
                    before={before_hoverLayer || before}
                    source={"censusSrc_" + uniqId}
                    type="line"
                    paint={{
                        "line-color": color_highlight_border,
                        "line-width": 2,
                    }}
                    filter={["==", ["id"], parseInt(highlightFeature.id, 10)]}
                />
            )}

            {hoveredEventState && !disabled && (
                <FeatureState
                    id={hoveredEventState.features[0].id}
                    source={"censusSrc_" + uniqId}
                    sourceLayer={layerName}
                    state={{ hover: true }}
                />
            )}
        </>
    );
};

CensusLayer.prototypes = {
    layerName: PropTypes.string.isRequired,
    onHover: PropTypes.func,
    onClick: PropTypes.func,
    selectedFeatures: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string, geo_level: PropTypes.string })),
    color_default_border: PropTypes.string,
    color_default_fill: PropTypes.string,
    color_hover_border: PropTypes.string,
    color_hover_fill: PropTypes.string,
    color_select_border: PropTypes.string,
    color_select_fill: PropTypes.string,
    color_highlight_border: PropTypes.string,
    color_highlight_fill: PropTypes.string,
    color_label: PropTypes.string,
    color_label_halo: PropTypes.string,
    filterIds: PropTypes.arrayOf(PropTypes.string),
    before: PropTypes.string,
    before_hoverLayer: PropTypes.string,
    lineWidth_defaultLayer: PropTypes.number,
    lineWidth_hoverLayer: PropTypes.number,
    lineWidth_selectLayer: PropTypes.number,
    disabled: PropTypes.bool,
    uniqId: PropTypes.number,
    suppressLabels: PropTypes.bool,
    highlightFeature: PropTypes.shape({ id: PropTypes.string, geo_level: PropTypes.string }),
};
CensusLayer.defaultProps = {
    color_default_border: "lightgray",
    color_default_fill: "transparent",
    color_hover_border: "rgba(180,180,180,1)",
    color_hover_fill: "rgba(143, 43, 0, 0.25)",
    color_select_border: "rgb(176, 176, 176)",
    color_select_fill: "rgba(176, 176, 176, 0.4)",
    color_highlight_border: "rgba(0,255,255,1)",
    color_highlight_fill: "rgba(0,255,255,0.25)",
    color_label: "black",
    color_label_halo: "rgb(176, 176, 176)",
    lineWidth_defaultLayer: 1,
    lineWidth_hoverLayer: 2,
    lineWidth_selectLayer: 1,
    disabled: false,
    uniqId: 1,
    suppressLabels: true,
};

export default CensusLayer;
