import React, { useRef, useState } from "react";
import { Anchor, Box,/* Button, */Drop, Heading, Text, TextInput, ThemeContext } from "grommet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faSpinner, faExclamationTriangle, faHouse, faMapMarkerCheck, faPencil, faFlagUsa, faLandmark, faMapMarkedAlt } from "@fortawesome/pro-regular-svg-icons";
import { faCity } from "@fortawesome/free-solid-svg-icons";
import { getRelativeBodySize, getRelativeHeadingSize } from "../../grommet-themes/he-main";

import useSearchData from "../../hooks/useSearchData";
// import { colors } from "grommet/themes/base";

const maxResults = 100;
const icons = {
    nation: <FontAwesomeIcon icon={faFlagUsa} fixedWidth color="#3D7199" />,
    tract: <FontAwesomeIcon icon={faHouse} fixedWidth color="#3D7199" />,
    place: <FontAwesomeIcon icon={faCity} fixedWidth color="#3D7199" />,
    county: <FontAwesomeIcon icon={faLandmark} fixedWidth color="#3D7199" />,
    state: <FontAwesomeIcon icon={faMapMarkerCheck} fixedWidth color="#3D7199" />,
    tribal: <FontAwesomeIcon icon={faMapMarkedAlt} fixedWidth color="#3D7199" />,
};

const SearchHeader = ({ searchLocation, setSearchLocation, placeholder, geoTypes, showIcon, screenSize, searchCache, autoFocus }) => {
    const targetRef = useRef();
    const [isEditorVisible, set_isEditorVisible] = useState(false);
    screenSize = screenSize || "desktop";
    return (
        <Box direction="row" align="center" gap="small">

            <Heading size={getRelativeHeadingSize("xlarge", screenSize)} level="2" margin="none" >
                {!showIcon ? null : !searchLocation ? (
                    <Box pad={{ right: "xsmall" }}>
                        <FontAwesomeIcon icon={faMapMarkerCheck} color="lightgrey" />
                    </Box>
                ) : (
                    <>{icons[searchLocation.geo_level]}&nbsp;</>
                )}
                {searchLocation ? searchLocation.alias || searchLocation.label : ""}
            </Heading>
            <Anchor
                ref={targetRef}
                onClick={() => {
                    set_isEditorVisible(!isEditorVisible);
                }}
            >
                <FontAwesomeIcon icon={faPencil} />
            </Anchor>
            {isEditorVisible && targetRef.current && (
                <Drop
                    margin={{ top: "10px" }}
                    align={{ top: "bottom" }}
                    target={targetRef.current}
                    onClickOutside={() => {
                        set_isEditorVisible(false);
                    }}
                >
                    <Box width="medium" pad="small" background="light-2">
                        <SearchInput
                            screenSize={screenSize}
                            setSearchLocation={setSearchLocation}
                            set_isEditorVisible={set_isEditorVisible}
                            placeholder={placeholder}
                            geoTypes={geoTypes}
                            searchCache={searchCache}
                            autoFocus={autoFocus}
                        />
                    </Box>
                </Drop>
            )}
        </Box>
    );
};

const SearchHeaderCompact = ({ searchLocation, setSearchLocation, placeholder, geoTypes, showIcon, screenSize, searchCache }) => {
    const targetRef = useRef();
    const [isEditorVisible, set_isEditorVisible] = useState(false);
    screenSize = screenSize || "desktop";
    return (
        <Box align="center" border={{size: "xsmall", color: "light-3"}} fill overflow="hidden">
            {/* <Button
                label={searchLocation ? searchLocation.alias || searchLocation.label : ""}
                icon={<FontAwesomeIcon icon={faSearch} />}
                onClick={() => {
                    set_isEditorVisible(!isEditorVisible);
                }}
                plain
                ref={targetRef}
                size="xlarge"
            /> */}
            <Box 
                direction="row"
                align="center"
                gap="small" 
                pad={{horizontal: "small"}}
                onClick={() => {
                        set_isEditorVisible(!isEditorVisible);
                    }}
                ref={targetRef}
                fill
                height='100px'
            >
                <FontAwesomeIcon icon={faSearch} size="1x"/>
                <Box>
                    <Heading size={getRelativeHeadingSize("large", screenSize)} level="2" margin="none" truncate>
                        {!showIcon ? null : !searchLocation ? (
                            <Box pad={{ right: "xsmall" }}>
                                <FontAwesomeIcon icon={faMapMarkerCheck} color="lightgrey" />
                            </Box>
                        ) : (
                            <>{icons[searchLocation.geo_level]}&nbsp;</>
                        )}
                        {searchLocation ? searchLocation.alias || searchLocation.label : ""}
                    </Heading>
                </Box>
            </Box>

            {isEditorVisible && targetRef.current && (
                <Drop
                    align={{ top: "top", left: "left" }}
                    target={targetRef.current}
                    elevation="none"
                    onClickOutside={() => {
                        set_isEditorVisible(false);
                    }}
                >
                    <Box overflow="hidden" fill>
                        <SearchInput
                            screenSize={screenSize}
                            setSearchLocation={setSearchLocation}
                            set_isEditorVisible={set_isEditorVisible}
                            placeholder={placeholder}
                            geoTypes={geoTypes}
                            searchCache={searchCache}
                        />
                    </Box>
                </Drop>
            )}
        </Box>
    );
};

const HighlightedText = ({text, highlight, position}) => {

    const commaPosition = text.indexOf(',');
    if (commaPosition<=position){
        position+=1;
    }
    let highlightLength = highlight.length;
    if (position<commaPosition && commaPosition <= (position+highlightLength) && !highlight.includes(',')) {
        highlightLength += 1;
    }

    const startString = position===0?null:text.substring(0,position);
    const highlightedString = text.substring(
        position,
        position+highlightLength+(position>0?1:0)
    );
    const endString = text.substring(position+highlightLength+(position>0?1:0),text.length);

    return (
        <span>
            {startString}
            <b>{highlightedString}</b>
            {endString}
        </span>
    )
}

const SearchInput = ({ setSearchLocation, set_isEditorVisible, placeholder, geoTypes, screenSize, searchCache, autoFocus, year }) => {
    geoTypes = geoTypes || "he-place,he-county,he-state";
    const inputRef = useRef();
    const [value, set_value] = useState("");
    const { status, suggestions, error } = useSearchData({ geoTypes: geoTypes, limit: maxResults, nameLike: value, searchCache: searchCache, year });
    screenSize = screenSize || "desktop";

    const updateSuggestions = (event) => {
        const value = event.target.value;
        set_value(value);
    };

    const customInputTheme = {
        global: {
            focus: {
                border: {
                    color: "heLightBlue",
                },
            },
            drop: {
                background: "light-1",
            },
            control: {
                border: {
                    radius: "0px",
                },
            },
        },
    };
    let inputStyle = { padding: "14px 40px" };
    if (screenSize !== "mobile") {
        inputStyle.fontSize = getRelativeBodySize("small", screenSize);
    }
    const formattedSuggestions = () => {
        if(suggestions==='fetching') return [];
        let out = suggestions.map((item) => {
            return {
                value: item,
                label: (
                    <Box direction="row" align="center" gap="small" pad="xxsmall">
                        {icons[item.geo_level]}
                        <Text size={getRelativeBodySize("small", screenSize)}>
                            <HighlightedText text={item.alias || item.label} highlight={inputRef.current.value} position={item.position}/>
                        </Text>
                    </Box>
                ),
            };
        });
        if (suggestions.length === maxResults) {
            out.push({
                value: { id: "more", label: "" },
                label: (
                    <Box background="dark-1" align="center" pad="small" onClick={null} style={{ cursor: "default" }}>
                        <Text size="xsmall">
                            <i>...continue typing to narrow results</i>
                        </Text>
                    </Box>
                ),
            });
        }
        return out;
    };

    const getSearchIcon = () => {
        if (status === "fetching") {
            return <FontAwesomeIcon icon={faSpinner} color="lightgrey" spin />;
        }
        if (suggestions.length === 0 && value !== "") {
            return <FontAwesomeIcon icon={faExclamationTriangle} color="lightgrey" />;
        }
        return <FontAwesomeIcon icon={faSearch} color="text" />;
    };

    return (
        <Box fill background="#fff" align="center">
            <ThemeContext.Extend value={customInputTheme}>
                <TextInput
                    defaultSuggestion={0}
                    style={inputStyle}
                    icon={getSearchIcon()}
                    value={value}
                    onChange={updateSuggestions}
                    onSelect={(evt) => {
                        if (evt.suggestion.value.id === "more") {
                            return;
                        }
                        setSearchLocation(evt.suggestion.value);
                        if (set_isEditorVisible) {
                            set_isEditorVisible(false);
                        }
                        set_value("");
                    }}
                    suggestions={formattedSuggestions()}
                    placeholder={placeholder || "Change location...."}
                    dropHeight="small"
                    dropProps={{ elevation: "large" }}
                    autoFocus={autoFocus===false?false : true}
                    ref={inputRef}
                />
                {inputRef.current && error && (
                    <Drop align={{ top: "bottom", left: "left" }} target={inputRef.current}>
                        <Box pad="small">There was an error fetching the search results!</Box>
                    </Drop>
                )}
            </ThemeContext.Extend>
        </Box>
    );
};

export { SearchInput, SearchHeader, SearchHeaderCompact };
