import React from "react";
import PropTypes from 'prop-types';
import { Header, Box } from "grommet";

function AppHeader(props) {
    return (
        <Header {...props} pad={{ vertical: "xxsmall", horizontal: "xsmall" }} direction="row" gap="xsmall">
            <Box align="start" flex="grow">{props.leftSideContent}</Box>

            <Box direction="row" align="center" gap="small">
                {props.rightSideContent}
                {props.menu}
            </Box>
        </Header>
    );
}

AppHeader.propTypes = {
    leftSideContent:PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    rightSideContent:PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    menu:PropTypes.element
};

export default AppHeader;
