function getApiRoot() {

    // first, assign based on hostname
    const hostName = document.location.hostname;
    let apiVersion = "prd";
    if (hostName === "hestaging2.wpengine.com") {
        apiVersion = "stg";
    } else if (hostName === "hedevelopment2.wpengine.com") {
        apiVersion =  "dev";
    } else if (hostName === "localhost") {
        apiVersion = "dev";
    }

    // override the apiVersion if apiVersion is pass as a global variable in the url
    const urlSearch = document.location.search;
    if (urlSearch.length > 1 && urlSearch.indexOf('?') === 0 ){
        const params = urlSearch.split("?")[1].split("&");
        params.forEach((param) => {
            const paramParts = param.split("=");
            if (paramParts.length > 1 && paramParts[0] === "globals" && paramParts[1].indexOf("apiVersion") === 0) {
                apiVersion = paramParts[1].split(":")[1];
            }
        });
    }

    const apiRoot = 
        apiVersion ==='local' ? "http://localhost:3999" :
        apiVersion ==='dev' ? "https://api-dev.headwaterseconomics.org" :
        apiVersion ==='stg' ? "https://api-stg.headwaterseconomics.org" :
        "https://api.headwaterseconomics.org";
    return apiRoot;
}

function getReportsApiRoot() {

    // first, assign based on hostname
    const hostName = document.location.hostname;
    let apiVersion = "prd";
    if (hostName === "hestaging2.wpengine.com") {
        apiVersion = "stg";
    } else if (hostName === "hedevelopment2.wpengine.com") {
        apiVersion =  "dev";
    } else if (hostName === "localhost") {
        apiVersion = "dev";
    }

    // override the apiVersion if apiVersion is pass as a global variable in the url
    const urlSearch = document.location.search;
    if (urlSearch.length > 1 && urlSearch.indexOf('?') === 0 ){
        const params = urlSearch.split("?")[1].split("&");
        params.forEach((param) => {
            const paramParts = param.split("=");
            if (paramParts.length > 1 && paramParts[0] === "globals" && paramParts[1].indexOf("apiVersion") === 0) {
                apiVersion = paramParts[1].split(":")[1];
            }
        });
    }

    const reportsApiRoot = 
        apiVersion ==='local' ? "http://localhost:3998" :
        apiVersion ==='dev' ? "https://reports-dev.headwaterseconomics.org" :
        apiVersion ==='stg' ? "https://reports-stg.headwaterseconomics.org" :
        "https://reports.headwaterseconomics.org";
    return reportsApiRoot;
}



function getLambdaEndpoint() {
    return getApiRoot()+'/getData';
}

function getDownloadShapeEndpoint() {
    return getApiRoot()+'/downloadShape';
}


class LambdaError extends Error {
    constructor(message) {
        super(message); // (1)
        this.name = "LambdaError";
    }
}

function getData(flavor, params) {
    let url = getLambdaEndpoint() + "?flavor=" + flavor;
    return new Promise(function (resolve, reject) {
        fetch(url, {
            method: "post",
            body: JSON.stringify(params),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.message || data.message === "Internal server error") {
                    try {
                        throw new Error("Error running lambda(" + flavor + ").");
                    } catch (e) {
                        console.error(e.name + ": " + e.message);
                        reject(Error(e.name + ": " + e.message));
                    }
                } else {
                    resolve(data.data);
                }
            })
            .catch(() => {
                const error = new LambdaError(
                    "Error running lambda. Flavor: " + flavor + ", params: " + JSON.stringify(params)
                );
                reject(Error(error.name + ": " + error.message));
                throw error;
            });
    });
}

function getShapeFile(flavor, fileName, geoIds, searchLocation, url) {
    const requestData = {
        fileName: fileName,
        geoIds: geoIds,
        searchLocation: searchLocation,
        url: url,
    };

    const handleResponseStatusErrors = (response) => {
        if (!response.ok) {
            const e = new LambdaError(
                "Error downloading shapefile (status:" + response.status + " ). " + JSON.stringify(requestData)
            );
            throw e;
        }
        return response;
    };

    const handleResponseTypeErrors = (data) => {
        if (!data.type.startsWith("application")) {
            const e = new LambdaError(
                "Error downloading shapefile (response type is not a file). " + JSON.stringify(requestData)
            );
            throw e;
        }
        return data;
    };

    return new Promise(function (resolve, reject) {
        function postData(url = getDownloadShapeEndpoint() + "?flavor=" + flavor, data = requestData) {
            return fetch(url, {
                method: "POST",
                // headers: new Headers({
                //     Accept:"application/zip"
                //     // "Content-Type": "application/zip; charset=utf-8",
                // }),
                body: JSON.stringify(data),
            })
                .then(handleResponseStatusErrors)
                .then((response) => {
                    return response.blob();
                });
        }

        postData()
            .then(handleResponseTypeErrors)
            .then((blob) => {
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = fileName + ".zip";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove(); //afterwards we remove the element again
                resolve();
            })
            .catch((e) => {
                // resolve the promise
                reject(e);
                // then throw the error for logging in sentry
                throw e;
            });
    });
}

export { getLambdaEndpoint, getData, getShapeFile, getApiRoot, getReportsApiRoot };
