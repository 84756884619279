import React from "react";

const Attribution = ({ attributionSide }) => {
    return (
        <div
            className="HeMapboxUrbica-attrib"
            style={attributionSide === "left" ? { left: 100 } : { right: 100 }}
        >
            <div className="HeMapboxUrbica-attrib-icon" style={{ fontFamily: "'Crimson Pro', serif" }}>
                i
            </div>
            <div className="HeMapboxUrbica-attrib-links">
                <a href="https://www.mapbox.com/about/maps/" target="_blank" rel="noopener noreferrer">
                    &copy; Mapbox
                </a>
                <a href="http://www.openstreetmap.org/about/" target="_blank" rel="noopener noreferrer">
                    &copy; OpenStreetMap
                </a>
                <a
                    href="https://apps.mapbox.com/feedback/?owner=headmin&amp;id=cjyqefhc701341cm0w666swww&amp;access_token=pk.eyJ1IjoiaGVhZG1pbiIsImEiOiJjaXQwOXUyY2wwbGdlMnpwZ2huMTNwb3VnIn0.e2ya7bTd2eFWlS4kSvTJVQ"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Improve this map
                </a>
            </div>
        </div>
    );
};

export default Attribution;

