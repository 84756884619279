import React, { useContext, useState } from "react";
import appContext from "../context/appContext";
import { Box, Meter, Text, Stack } from "grommet";
// import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faUsdCircle, faUsers } from "@fortawesome/pro-solid-svg-icons";

const populationFormatter = new Intl.NumberFormat("en-US", {
    style: "decimal",
    maximumFractionDigits: 0,
    notation: "compact"
});
const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    notation: "compact"
});
const percentFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    maximumFractionDigits: 0,
});

const dataColor = {   
    default: (
        [
            "#031E33",
            "#0D4D80",
            "#2984CD",
            "#8D1E07",
            "#331800",
            "#B35A48",
            "#B39448"
        ]
    ),
    cool: (
        [
            "#0D4D80",
            "#157BCD",
            "#2A2B8D",
            "#293BCD",
            "#2A8D6F",
            "#29CDB9",
            "#072740"
        ]
    ),
    grayscale: (
        [
            "#222",
            "#555",
            "#777",
            "#aaa",
            "#b9b9b9",
            "#ddd",
            "#fff"
        ]
    ),
    warm: (
        [
            "#B31F00",
            "#340900",
            "#C0513A",
            "#801600",
            "#E1A599",
            "#994533",
            "#D17966"
        ]
    )
};

const MicroQfBarChart = ({ data, labelWidth, barSize, colors = dataColor.grayscale }) => {
    const barData = data.map(( value, i ) => {
        return (
            <Stack key={value.group}>
                <Meter 
                    type="bar"
                    thickness="20"
                    background="light-2"
                    color="heBlue-3"
                    values={[{
                        value: (value.propOfPop*100), label: value.group, color: colors[i]
                    }]}
                />
                <Box fill direction="row" justify="between" align="center" pad={{horizontal: "xxsmall"}}>
                    <Text size="8pt" color="#fff">{percentFormatter.format(value.propOfPop)}</Text>
                    <Text size="11pt">{value.group}</Text>
                </Box>
                
            </Stack>
        )
    });
    return (
        <Box flex={false} width="150px" align="center">
            {barData}
        </Box>
    );
};

const MicroQfRingChart = ({ data, chartTitle = '', chartSubtitle = '', barSize, colors = dataColor.grayscale }) => {
    const [value, setValue] = useState();
    const [label, setLabel] = useState();
    const ringData = data.map(( value, i ) => {
        let dataValue = percentFormatter.format(value.propOfPop);
        let dataLabel = value.group
        return (
            {
                value: (value.propOfPop*100), 
                label: value.group, 
                color: colors[i], 
                onHover: over => {
                    setValue(over ? dataValue : undefined);
                    setLabel(over ? dataLabel : undefined);
                }
            }
        )
    });
    return (
        <Box align="center" pad="xsmall">
            <Box direction="row" anchor="center">
                <Meter 
                    size="50"
                    type="circle"
                    background="light-2"
                    color="heBlue-3"
                    values={ringData}
                    thickness="25"
                />
                <Box align="center" width="70px" round="100%" pad={{bottom: "xsmall"}}>
                    {!value ? (
                        <>
                        <Text size="11pt" weight="bold" textAlign="center">{chartTitle}</Text>
                        <Text size="9pt" textAlign="center">{chartSubtitle}</Text> 
                        </>
                    ) : (
                        <>
                        <Text size="11pt">
                            {value}
                        </Text>
                        <Text size="11pt" weight="bold" textAlign="center">
                            {label}
                        </Text>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};


const MicroQuickFacts = ({ primaryGeo, secondaryGeo, suppressHeader, format }) => {
    const { quickFactsData, acsYear } = useContext(appContext);

    if (!quickFactsData[primaryGeo]) return null;
    const primaryGeoQuickFacts = quickFactsData[primaryGeo];

    return (
        <>
        <Box background="#fff" elevation="xsmall" direction="row" round="2px" gap="xsmall" justify="between" align="center" pad={{vertical: "xsmall"}}>
            <Box flex="grow" direction="row" align="center" gap="xsmall" pad="xxsmall" background="light-2" round="3px">
                <FontAwesomeIcon icon={faUsers} size="lg" />
                {/* <Text weight={300} size="xsmall">Pop.</Text> */}
                <Text weight={900} size="small">
                    {!primaryGeo ? 'na' : populationFormatter.format(quickFactsData[primaryGeo].population)}
                </Text>
            </Box>
            <Box direction="row" align="center" gap="xsmall" pad="xxsmall" background="light-2" round="3px">
                <Stack anchor="top-right" margin={{top: "-3px"}}>
                    <Box pad={{top: "5px", right: "5px"}}>
                        <FontAwesomeIcon flip="horizontal" icon={faHouse} size="1x" />
                    </Box>
                    <Box background="#fff" round="100%">
                        <FontAwesomeIcon color="#718C49" icon={faUsdCircle} size="sm" />
                    </Box>
                </Stack>
                {/* <Text weight={300} size="xsmall">MHI</Text> */}
                <Text weight={900} size="small">
                    {currencyFormatter.format(primaryGeoQuickFacts.income)}
                </Text>
            </Box>
        </Box>
        {format === "expanded" ? (
            <Box direction="row">
                <MicroQfRingChart data={primaryGeoQuickFacts.workStatus} chartTitle="Employ-ment" chartSubtitle={acsYear} />
                <MicroQfBarChart data={primaryGeoQuickFacts.workStatus} chartTitle="Employ-ment" chartSubtitle={acsYear} />
            </Box>
        ) : null }
        </>
    )
}

export { MicroQuickFacts };