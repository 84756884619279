import React from "react";

const Logo = ({ logo }) => {
    if (!logo) return null;
    return (
        <div className="HeMapboxUrbica-logo" >
            {logo}
        </div>
    );
};
export default Logo;
